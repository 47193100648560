import styled from "styled-components";
// styled components
// Drop Background Styled Component
// svgfile of the drop: ltr || rtl || ltrt || ltrb
// x position of the drop: left || right or %
// y position of the drop: % from top (ex -40%)

const DropBg = styled.img.attrs(props => ({
    src: props.src
}))`
    position: absolute;
    pointer-events: none;
    width: ${props => props.width || 'unset'};
    max-width: ${props => props.noOverflow ? '100%' : 'unset'};
    filter: ${props => props.blur ? 'blur(50px)' : 'unset'};
    will-change: ${props => props.blur ? 'filter' : 'unset'};
    top:${(props) => props.top};
    right:${(props) => props.right};
    left:${(props) => props.left};
    bottom:${(props) => props.bottom};
    z-index:${(props) => props.zIndex};
    transform:${props => props.rotation ? `rotate(${props.rotation}deg)` : 'unset'};
        @media screen and (max-width:768px) {
            display:${props => props.hideOnMobile ? 'none!important' : 'unset'};
            top:${props => props.mTop};
            right:${props => props.mRight};
            left:${props => props.mLeft};

      }
      @media screen and (max-width:991px) {
            display:${props => props.hideOnTablet ? 'none!important' : 'unset'};
      }
      @media screen and (max-width:1200px) {
            display:${props => props.hideOnLG ? 'none!important' : 'unset'};
      }
`;

export default DropBg;
