import React from "react";
import withLayout from "../layout";
import {Heading} from "../components/UI/Typography";
import {FormattedMessage, injectIntl} from "react-intl";
import Dash from "../components/UI/Dash";
import JobsReasonsParagraph from "../components/UI/JobsReasonsParagraph";
import Hero from '../components/Hero';
import DropBg from '../components/UI/DropBg';
import SEO from "../components/SEO";
import CollapsableTable from "../components/UI/CollapsableTable";
import DropBlurVioletBg from "../images/jobs/DropBlurVioletBg.svg";

const INTERNSHIPS = [
    {
        title: 'internships.internship_type1',
        rows: [
            {
                paragraph: 'internships.internship_type1_text',
                link: '/internship-detail/extracurricular'
            }
        ]
    },
    {
        title: 'internships.internship_type2',
        rows: [
            {
                paragraph: 'internships.internship_type2_text',
                link: '/internship-detail/curricular'
            }
        ]
    }
];

const InternshipsPage = ({intl}) => {
    const keywords = intl.formatMessage({id: 'internships.meta_keywords'}).split(',') || [];

    return <>
        <SEO title={intl.formatMessage({id: 'internships.meta_title'})}
             description={intl.formatMessage({id: 'internships.meta_description'})} keywords={keywords}
             lang={intl.locale}/>

        <Hero title={'internships.title'} subtitle={'internships.subtitle'} thinChild={'1'} paragraph={'internships.paragraph'}/>
        <div className="section-padding-top section-padding-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <Heading level={"2"} thinChild={1}>
                            <FormattedMessage id={"internships.title_one_thin"}/>
                            <FormattedMessage id={"internships.title_one"}/>
                        </Heading>
                        <Dash/>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-6 col-md-6 col-12 text-center pr-4">
                        <JobsReasonsParagraph label={"01"} description={"internships.text_one"}
                                              title={"internships.subtitle_one"}/>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 text-center pr-4">
                        <JobsReasonsParagraph label={"02"} description={"internships.text_two"}
                                              title={"internships.subtitle_two"}/>
                    </div>
                </div>
            </div>
        </div>
        <div className="section-gray">
            <DropBg src={DropBlurVioletBg} top={"0%"} noOverflow={true}/>
            <div className="section-inner-padding">
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-12">
                            <Heading level={"3"} className="my-0">
                                <FormattedMessage id={"internships.title_two"}/>
                            </Heading>
                        </div>
                    </div>
                    <div className="row">
                        <div className={"col-12"}>
                            <CollapsableTable groups={INTERNSHIPS} isInternships={true} intl={intl}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
const customProps = {
    localeKey: "internships", // same as file name in src/i18n/translations/your-lang/index.js
};

export default withLayout(customProps)(injectIntl(InternshipsPage));
